var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.box},[_c('el-form-item',{class:_vm.$style.elFormItem,attrs:{"label":_vm.selectProps.title,"prop":_vm.curNodePath,"rules":[
                {
                    validator: function validator(rule, value, callback) {
                        var validProperties = ['imgUrl', 'imgLink'];

                        // 针对叶子节点做校验
                        var errors = [];
                        var isValidate = validProperties.every(function (item) {
                            errors = _vm.schemaValidate.validateFormDataAndTransformMsg({
                                formData: value[item],
                                schema: _vm.$props.schema.properties[item],
                                customFormats: _vm.$props.customFormats,
                                errorSchema: _vm.$props.errorSchema[item],
                                required: _vm.$props.schema.required.includes(item),
                                propPath: _vm.$props.curNodePath
                            });
                            return errors.length <= 0;
                        });

                        if (isValidate) { return callback(); }

                        return callback(errors[0].message);
                    },
                }
            ],"required":_vm.elItemRequired}},[(_vm.selectProps.description)?_c('div',{class:_vm.$style.description,domProps:{"innerHTML":_vm._s(_vm.selectProps.description)}}):_vm._e(),_c('div',{class:_vm.$style.formItem},[_c('div',{class:_vm.$style.uploadBox},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"#","show-file-list":false,"auto-upload":false,"on-change":_vm.handleAvatarSuccess}},[(_vm.imgUrl)?_c('img',{staticClass:"avatar",staticStyle:{"max-width":"100%","max-height":"100%"},attrs:{"src":_vm.imgUrl}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1),_c('el-input',{class:_vm.$style.input,attrs:{"placeholder":_vm.placeholder,"size":"medium"},model:{value:(_vm.imgLink),callback:function ($$v) {_vm.imgLink=$$v},expression:"imgLink"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }