<template>
	<div :class="$style.box">
		<el-form-item :label="selectProps.title" :prop="curNodePath" :class="$style.elFormItem" :rules="[
                {
                    validator(rule, value, callback) {
                        const validProperties = ['imgUrl', 'imgLink'];

                        // 针对叶子节点做校验
                        let errors = [];
                        const isValidate = validProperties.every(item => {
                            errors = schemaValidate.validateFormDataAndTransformMsg({
                                formData: value[item],
                                schema: $props.schema.properties[item],
                                customFormats: $props.customFormats,
                                errorSchema: $props.errorSchema[item],
                                required: $props.schema.required.includes(item),
                                propPath: $props.curNodePath
                            });
                            return errors.length <= 0;
                        });

                        if (isValidate) return callback();

                        return callback(errors[0].message);
                    },
                }
            ]" :required="elItemRequired">
			<div v-if="selectProps.description" :class="$style.description" v-html="selectProps.description"></div>
			<div :class="$style.formItem">
				<div :class="$style.uploadBox" >
					<!-- @click="selectImg" -->
					<!-- <img v-if="imgUrl" :src="imgUrl" alt="" style="max-width: 100%;max-height: 100%;">
					<i v-else class="el-icon-plus"></i> -->
					
					<el-upload class="avatar-uploader" action="#" :show-file-list="false" :auto-upload="false" :on-change="handleAvatarSuccess">
					  <img v-if="imgUrl" :src="imgUrl" class="avatar" style="max-width: 100%;max-height: 100%;">
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</div>
				<el-input v-model="imgLink" :class="$style.input" :placeholder="placeholder" size="medium"></el-input>
			</div>
		</el-form-item>
	</div>
</template>

<script>
	// 覆盖默认field 做个性商品选择和链接输入
	import {
		fieldProps,
		vueUtils,
		formUtils,
		schemaValidate
	} from '@lljj/vue-json-schema-form';

	export default {
		name: 'LinkImgField',
		props: {
			...fieldProps,
			fieldProps: {
				type: null,
				default: null
			}
		},
		data() {
			return {
				schemaValidate,
				vueUtils
			};
		},
		computed: {
			elItemRequired() {
				// 配置了 required 的属性提示小红点
				return this.schema.required.length > 0;
			},
			placeholder() {
				const imgLinkOptions = formUtils.getUiOptions({
					schema: this.schema.properties.imgLink,
					uiSchema: this.uiSchema.imgLink || {}
				});
				return imgLinkOptions.placeholder || '请输入合法的链接';
			},
			selectProps() {
				return formUtils.getUiOptions({
					schema: this.schema,
					uiSchema: this.uiSchema
				});
			},
			curValue() {
				return vueUtils.getPathVal(this.rootFormData, this.curNodePath);
			},
			imgUrl: {
				get() {
					return this.curValue.imgUrl;
				},
				set(value) {
					vueUtils.setPathVal(this.rootFormData, vueUtils.computedCurPath(this.curNodePath, 'imgUrl'),value);
					console.log('value',this.selectProps, this.rootFormData)
					if(this.rootFormData.title=="品牌专区") sessionStorage.setItem('brand',JSON.stringify(this.rootFormData.imgList));
					if(!this.rootFormData.title&&this.rootFormData.imgList)sessionStorage.setItem('CarouselImg',JSON.stringify(this.rootFormData.imgList));
				}
			},
			imgLink: {
				get() {
					return this.curValue.imgLink;
				},
				set(value) {
					vueUtils.setPathVal(this.rootFormData, vueUtils.computedCurPath(this.curNodePath, 'imgLink'),value);
				}
			}
		},
		methods: {
			selectImg() {
				const imgs = [
					'https://m.360buyimg.com/babel/jfs/t1/136164/18/4633/216335/5f1176b9E0d2e6f59/e41c72e01932fd73.jpg.webp',
					'https://gw.alicdn.com/tfs/TB1DKP9zCtYBeNjSspkXXbU8VXa-1920-450.jpg_Q90.jpg',
					'https://img.alicdn.com/tps/i4/TB1fbhiawoQMeJjy0FnSuv8gFXa.jpg_490x490q100.jpg_.webp',
					'https://gw.alicdn.com/tfs/TB1UzOqoWL7gK0jSZFBXXXZZpXa-468-602.jpg',
					'https://img.alicdn.com/tfs/TB1XjMYnfb2gK0jSZK9XXaEgFXa-468-1236.jpg',
					'https://img.alicdn.com/tps/i4/TB1MesKcWmWQ1JjSZPhwu0CJFXa.png',
					'https://gw.alicdn.com/tfs/TB1xVR9oFP7gK0jSZFjXXc5aXXa-468-602.jpg',
					'https://img.alicdn.com/tps/i4/TB1R8tlXxvbeK8jSZPfSuuriXXa.jpg_490x490q100.jpg_.webp'
				];
				console.log('上传图片')
				this.$message.success('选择图片成功，随机一个图片');
				this.imgUrl = imgs[Math.floor(Math.random() * imgs.length)];
			},
			
			handleAvatarSuccess(e){
				console.log(e)
				var formData = new FormData();
				formData.append('file', e.raw);
				this.uploadimg(formData).then(data=>{
					console.log(data)
					if (data.status == 'success') {
						this.imgUrl=data.data
						this.$notify.success({title: '上传成功',message: '您的图片已上传成功',type: 'success'});
						
					} else {
						this.$notify.error({title: '错误',message: data.message,position: 'top-content'});
					}
				});
			}
		}
	};
</script>
<style>
	.genFromComponent .arrayOrderList_item,.arrayListItem_content{padding-top: 0 !important;}
</style>
<style module>
	@import '../../../demo-common/css/variable.css';

	.box {
		:global {
			.el-form-item.is-error {
				:local {
					.uploadBox {
						color: #F56C6C;
					}
				}
			}
		}
	}

	:global {
		.arrayOrderList_item {
			:local {
				.elFormItem {
					margin-bottom: 0;
				}
			}
		}
	}

	.formItem {
		align-items: center;
		display: flex;
	}

	.input {
		flex: 1;
		margin-left: 15px;
	}

	.description {
		font-size: 12px;
		line-height: 20px;
		margin-bottom: 10px;
		color: var(--color-text-light)
	}

	.uploadBox {
		cursor: pointer;
		width: 80px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #e2e2e2;
		overflow: hidden;
	}
</style>
